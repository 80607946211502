<template>
  <div class="home-heroes">
    <div class="container-padding">
      <div class="home-heroes__header">
        <h2>Наши герои</h2>
        <!--        <div class="home-heroes__tabs">-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            aria-label="Сменить тип слайдера"-->
        <!--            title="Сменить тип слайдера"-->
        <!--            class="home-heroes__tab"-->
        <!--            @click="changeTabs(tabs.carousel)"-->
        <!--            v-show="activeTab === tabs.grid"-->
        <!--          >-->
        <!--            <IconComponent name="switch-to-carousel" />-->
        <!--          </button>-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            aria-label="Сменить тип слайдера"-->
        <!--            title="Сменить тип слайдера"-->
        <!--            class="home-heroes__tab"-->
        <!--            @click="changeTabs(tabs.grid)"-->
        <!--            v-show="activeTab === tabs.carousel"-->
        <!--          >-->
        <!--            <IconComponent name="switch-to-grid" />-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
    <HomeCarouselSlider :heroes="heroes_carousel_slider" v-if="activeTab === tabs.carousel" />
    <HomeGridSlider :heroes="heroes_grid_slider" v-if="activeTab === tabs.grid" />
  </div>
</template>

<script>
// import HomeHeroesSlider from "./HomeCarouselSlider.vue";
import HomeGridSlider from "./HomeGridSlider.vue";
// import IconComponent from "components/IconComponent.vue";
import HomeCarouselSlider from "./HomeCarouselSlider.vue";

export default {
  name: "HomeHeroesComponent",
  props: {
    heroes_carousel_slider: {
      type: Array,
      default: () => [],
    },
    heroes_grid_slider: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 1,
      tabs: {
        carousel: 1,
        grid: 2,
      },
    };
  },
  // mounted() {
  // this.activeTab = parseInt(localStorage.getItem("sliderTab")) || 1;
  // },
  // methods: {
  //   changeTabs(e) {
  //     this.activeTab = e;
  //     localStorage.setItem("sliderTab", e);
  //   },
  // },
  components: {
    HomeCarouselSlider,
    // IconComponent,
    HomeGridSlider,
    // HomeHeroesSlider,
  },
};
</script>

<style lang="stylus">
.home-heroes {
  display flex
  flex-direction column

  &__header {
    display flex
    align-items center
    justify-content space-between
    margin-bottom 100px
    +below(550px) {
      margin-bottom 50px
    }
  }

  &__tabs {
    display flex
    align-items center
    gap 10px
  }

  &__tab {
    background none
    border none
    cursor pointer
    transition .3s
  }

}
</style>
