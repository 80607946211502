<template>
  <div class="home-carousel-slider">
    <div v-if="heroes && heroes.length" class="home-carousel-slider__slides">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in heroes" :key="i">
            <router-link :to="{ name: 'hero', params: { id: item.id } }" class="home-carousel-slider__item">
              <ImgComponent width="320" height="320" :img="item.img" class="home-carousel-slider__item-img" />
            </router-link>
          </div>
        </div>
      </div>
      <SliderArrowsComponent
        v-if="swiper"
        :isBeginning="swiper.isBeginning"
        :isEnd="swiper.isEnd"
        @left="swiper.slidePrev()"
        @right="swiper.slideNext()"
      />
    </div>
    <div class="container-padding">
      <div v-if="activeItem" class="home-carousel-slider__item-info">
        <span v-if="activeItem.title" class="home-carousel-slider__item-title">{{ activeItem.title }}</span>
        <span v-if="activeItem.rank" class="home-carousel-slider__item-rank">{{ activeItem.rank }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import HEROES_CAROUSEL_SLIDER from "@/graphql/queries/heroes_carousel_slider.graphql";
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";

export default {
  name: "HomeCarouselSlider",
  props: {
    heroes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      first: 30,
      page: 1,
      swiper: null,
      activeItem: null,
      activeIndex: 15,
      paginationTypes: {
        before: 1,
        after: 2,
      },
    };
  },
  mounted() {
    this.activeItem = this.heroes[this.activeIndex];
    this.initializeSwiper();
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  watch: {
    heroes() {
      this.$nextTick(() => {
        this.updateSwiper(); // Вызываем метод обновления слайдера после изменения массива items
      });
    },
  },
  methods: {
    initializeSwiper() {
      require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
        this.swiper = new Swiper(this.$refs.items, {
          // speed: 1000,
          initialSlide: this.activeIndex,
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          effect: "coverflow",
          coverflowEffect: {
            rotate: 0,
            // stretch: 0,
            stretch: 21,
            // depth: 106,
            depth: 106,
            modifier: 1,
            slideShadows: false,
          },
          on: {
            slideChange: this.slideChange,
          },
        });
      });
    },
    updateSwiper() {
      if (this.swiper) {
        this.swiper.update(); // Обновляем слайдер
      }
    },
    async get() {
      return await this.$apollo.provider.defaultClient.query({
        query: HEROES_CAROUSEL_SLIDER,
        variables: {
          first: this.first,
          page: this.page,
        },
      });
    },
    paginate(type) {
      this.page += 1;
      if (type === this.paginationTypes.before) {
        this.get().then(({ data }) => {
          this.heroes.unshift(...data.heroes_paginate.data);
        });
      } else {
        this.get().then(({ data }) => {
          this.heroes.push(...data.heroes_paginate.data);
        });
      }
    },
    slideChange() {
      if (this.swiper) {
        this.activeIndex = this.swiper.activeIndex;
        this.activeItem = this.heroes[this.swiper.activeIndex];
        if (this.swiper.slides.length - this.swiper.activeIndex <= 5) {
          this.paginate(this.paginationTypes.after);
        } else if (this.activeIndex <= 10) {
          this.paginate(this.paginationTypes.before);
          this.swiper.activeIndex = this.swiper.activeIndex + this.first;
          this.activeIndex = this.swiper.activeIndex + this.first;
        }
      }
    },
  },
  components: { SliderArrowsComponent, SliderComponent, ImgComponent },
};
</script>

<style lang="stylus">
.home-carousel-slider {
  display flex
  flex-direction column
  margin-bottom 70px
  +below(428px) {
    margin-bottom 25px
  }

  &__slides {
    position relative
    height 100%
    margin-bottom 15px
    +below(428px) {
      margin-bottom 10px
    }
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 320px;
    height: 320px;
    //transition: none
    +below(428px) {
      width: 180px;
      height: 180px;
    }
  }

  .slider-arrows {
    +below(768px) {
      display none
    }

    &__item {
      width 40px
      height 40px
      background transparent
      border-color var(--white)

      &:not([disabled]):hover {
        background var(--white)
        border-color var(--white)

        .icon svg path {
          transition .3s
          fill var(--black)
        }
      }

      &--left {
        left 160px
        +below(1024px) {
          left 60px
        }
      }

      &--right {
        right 160px
        +below(1024px) {
          right 60px
        }
      }
    }

    .icon {
      width 20px
      height 20px

      svg path {
        transition .3s
        fill var(--white)
      }
    }
  }

  &__item {
    width 100%
    height 100%
  }

  &__item-info {
    display flex
    flex-direction column
    align-items center
    gap 15px
    //margin-bottom 70px
    text-align center
    height 125px
    max-height 125px
    +below(428px) {
      gap 5px
      //margin-bottom 25px
    }
  }

  &__item-img {
    display block
    width 100%
    height 100%
    border-radius var(--big-radius)
  }

  &__item-title {
    font-size 1.375rem
    font-weight 700
    line-height 32px
    max-width 320px
    +below(428px) {
      font-size .875rem
      line-height 22px
      max-width 180px
    }
  }

  &__item-rank {
    font-size 1rem
    line-height 26px
    display -webkit-box
    overflow hidden
    -webkit-box-orient vertical
    -webkit-line-clamp 3
    line-clamp 3
    max-width 320px
    +below(428px) {
      font-size .875rem
      line-height 22px
      max-width 180px
    }
  }
}
</style>
