<template>
  <div class="home-grid-slider">
    <div class="container-padding">
      <div v-if="heroes && heroes.length" class="home-grid-slider__slides">
        <div class="swiper" ref="items">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in heroes" :key="i">
              <router-link :to="{ name: 'hero', params: { id: item.id } }" class="home-grid-slider__item">
                <div class="home-grid-slider__item-info">
                  <span v-if="item.title" class="home-grid-slider__item-title">{{ item.title }}</span>
                  <span v-if="item.rank" class="home-grid-slider__item-rank">{{ item.rank }}</span>
                </div>
                <ImgComponent :img="item.img" width="320" height="320" class="home-grid-slider__item-img" />
              </router-link>
            </div>
          </div>
        </div>
        <SliderArrowsComponent
          v-if="swiper"
          :isBeginning="swiper.isBeginning"
          :isEnd="swiper.isEnd"
          @left="swiper.slidePrev()"
          @right="swiper.slideNext()"
        />
      </div>
      <span v-else>В данный момент информация отсутствует</span>
    </div>
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";

export default {
  name: "HomeGridSlider",
  props: {
    heroes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiper: null,
      activeIndex: 0,
    };
  },
  mounted() {
    this.activeIndex = Math.ceil(this.heroes.length / 4);
    this.initializeSwiper();
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  methods: {
    initializeSwiper() {
      require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
        this.swiper = new Swiper(this.$refs.items, {
          grabCursor: true,
          initialSlide: this.activeIndex,
          slidesPerView: 1,
          spaceBetween: 32,
          grid: {
            rows: 2,
            fill: "row",
          },
          breakpoints: {
            550: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          },
        });
      });
    },
  },
  components: { SliderArrowsComponent, ImgComponent, SliderComponent },
};
</script>

<style lang="stylus">
.home-grid-slider {
  margin-bottom 100px
  +below(550px) {
    margin-bottom 50px
  }

  &__slides {
    position relative
    height 100%
  }

  .swiper {
    width 100%
    height 100%
  }

  &__item {
    width 100%
    height 100%
    position relative
    &:hover {
      .home-grid-slider__item-info {
        visibility visible
        opacity 1
        transition .3s
      }
    }
  }

  &__item-info {
    display flex
    flex-direction column
    justify-content flex-end
    gap 10px
    visibility hidden
    opacity 0
    transition .3s
    padding 0 12px 15px 12px
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    width 100%
    height 100%
    absolute top 0 left 0
    text-align: center;
  }

  &__item-title {
    font-size 1.625rem
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.04em;
  }

  &__item-rank {
    font-size 1rem
    font-weight: 400;
    line-height: 22px;
  }

  &__item-img {
    max-width 289px
    height 289px
    max-height 289px
    border-radius 35px
    +below(550px) {
      max-width 400px
      height 350px
      max-height 350px
    }
  }

  .slider-arrows {
    +below(768px) {
      display none
    }

    &__item {
      width 40px
      height 40px
      background transparent
      border-color var(--black)

      &:not([disabled]):hover {
        background var(--gray-3)
        border-color var(--black)

        .icon svg path {
          transition .3s
          fill var(--black)
        }
      }

      &--left {
        left -60px
        +below(1024px) {
          left -25px
        }
      }

      &--right {
        right -60px
        +below(1024px) {
          right -25px
        }
      }
    }

    .icon {
      width 20px
      height 20px

      svg path {
        transition .3s
        fill var(--black)
      }
    }
  }

  //.swiper-slide {
  //  width 100%
  //  height 100%
  //}
}
</style>
