<template>
  <div class="home-banner">
    <img src="/static/images/banner.webp" alt="Главная фотография" class="home-banner__image" />
    <div class="home-banner__inner">
      <div class="container-padding">
        <div class="home-banner__content">
          <div class="home-banner__header">
            <IconComponent name="medal" class="home-banner__header-icon" />
            <span class="home-banner__header-title">Герои</span>
            <span class="home-banner__header-title">спецоперации</span>
          </div>
          <div class="home-banner__bottom">
            <div class="home-banner__author">
              <img
                src="/static/images/melikov.webp"
                alt="Фотография Сергея Алимовича Меликова"
                class="home-banner__author-image"
              />
              <div class="home-banner__author-text">
                <span class="home-banner__author-post">{{ post }}</span>
                <span class="home-banner__author-name">{{ name }}</span>
              </div>
            </div>
            <div class="home-banner__quote">
              <div class="home-banner__quote-left">
                <IconComponent name="quotes" class="home-banner__quote-icon" />
              </div>
              <span class="home-banner__quote-title">{{ text }}</span>
              <div class="home-banner__quote-right">
                <IconComponent name="quotes" class="home-banner__quote-icon" />
              </div>
            </div>
            <span class="home-banner__subtext">{{ subtext }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-banner__blur" />
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HomeBannerComponent",
  data() {
    return {
      title: "Герои спецоперации",
      text: "Мы защищаем ценности, близкие каждому из нас. На наших глазах простые дагестанские парни стали Героями. Наш храбрый и свободолюбивый народ много раз за свою тысячелетнюю историю бросал все силы для Победы и давал отпор врагу только по одной причине – из-за любви к своей земле",
      post: "Глава Республики Дагестан,",
      name: "Сергей Алимович Меликов",
      subtext:
        "Разработка проекта ведется Министерством цифрового развития Республики Дагестан совместно с сообществом «Сила V единстве»",
    };
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.home-banner {
  position relative
  color var(--white)

  &__image {
    height 1060px
    object-fit cover
    +below(1200px) {
      object-position 60%
    }
    +below(850px) {
      height 920px
    }
    +below(650px) {
      height 730px
    }
    +below(428px) {
      height 630px
      object-position 70%
    }
  }

  &__inner {
    width 100%
    position absolute
    top 0
  }

  &__content {
    position absolute
    top 235px
    display flex
    flex-direction column
    gap 80px
    +below(850px) {
      top 205px
    }
    +below(650px) {
      gap 50px
      top 180px
    }
    +below(428px) {
      top 150px
      gap 30px
    }
  }

  &__blur {
    position absolute
    bottom -1px
    background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.09) 20.15%, #FFFFFF 100%)
    width 100%
    height 150px
    +below(850px) {
      height 120px
    }
    +below(650px) {
      height 100px
    }
    +below(428px) {
      height 80px
    }
  }

  &__header {
    max-width 610px
    max-height 162px
    display grid
    grid-template-columns: 80px 510px
    align-items center
    grid-gap 15px 13px
    +below(650px) {
      grid-template-columns: 52px 249px
      grid-gap 0 13px
    }
  }

  &__header-title {
    font-size 4.375em
    font-weight 800
    line-height 67px
    text-transform uppercase
    +below(650px) {
      font-size 2.5em
      line-height 48px
    }
    +below(428px) {
      font-size 2.125em
    }
  }

  &__header-icon {
    max-width 80px
    max-height 80px
    +below(650px) {
      max-width 52px
      max-height 52px
    }
  }

  &__bottom {
    display flex
    flex-direction column
    gap 30px
  }

  &__quote {
    position relative
    max-width 613px
    display flex
    //align-items flex-start
    gap 10px
    +below(650px) {
      max-width 368px
    }
    +below(428px) {
      max-width 350px
      gap 5px
    }
  }

  &__quote-left {
    width 100%
    max-width 19px
    display flex
    align-items flex-start
  }

  &__quote-right {
    width 100%
    max-width 19px
    display flex
    align-items flex-start
    transform: rotate(180deg);
    absolute bottom 0px right 285px
    +below(650px) {
      right 50px
    }
    +below(428px) {
      right 80px
    }
    +below(379px) {
      right 40px
    }
  }

  &__quote-icon {
    margin-top 10px
    max-width 19px
    max-height 24px
    +below(650px) {
      margin-top 6px
    }
  }

  &__quote-title {
    font-size 1.25em
    font-weight 600
    line-height 32px
    +below(650px) {
      font-size .875em
      font-weight 500
      line-height 22px
    }
    +below(428px) {
      font-size .75em
    }
  }

  &__author {
    display flex
    align-items center
    gap 23px
    +below(428px) {
      gap 15px
    }
  }

  &__author-image {
    width 100%
    max-width 80px
    height 80px
    max-height 80x
    border 1px solid var(--white)
    filter drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.15))
    border-radius 100px
    +below(650px) {
      width 100%
      max-width 60px
      height 60px
      max-height 60x
    }
  }

  &__author-text {
    display flex
    flex-direction column
    gap 5px
  }

  &__author-post {
    font-size .875em
    line-height 22px
    +below(428px) {
      font-size .75em
    }
  }

  &__author-name {
    font-size .875em
    font-weight 700
    line-height 22px
    +below(428px) {
      font-size .75em
    }
  }

	&__subtext {
		padding-top 20px
		max-width 470px
		font-size .875rem
		line-height 17.3px
		+below(650px) {
			max-width 90%
		}
		+below(428px) {
			font-size: 0.75rem;
			padding-top 0
		}
	}
}
</style>
