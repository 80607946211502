<template>
  <div class="home-page page">
    <HomeBannerComponent />
    <HomeHeroesComponent
      :heroes_carousel_slider="heroes_carousel_slider"
      :heroes_grid_slider="heroes_grid_slider"
    />
  </div>
</template>

<script>
import HomeHeroesComponent from "./components/HomeHeroesComponent.vue";
import HomeBannerComponent from "./components/HomeBannerComponent.vue";
import HOME_PAGE from "@/graphql/pages/home_page.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
        variables: {
          first: 30,
          page: 1,
        },
      })
      .then(({ data }) => {
        store.state.home_page.heroes_carousel_slider = data.heroes_paginate.data;
        store.state.home_page.heroes_grid_slider = data.heroes;
      });
  },
  computed: {
    heroes_carousel_slider() {
      return this.$store.state.home_page.heroes_carousel_slider;
    },
    heroes_grid_slider() {
      return this.$store.state.home_page.heroes_grid_slider;
    },
  },
  metaInfo: {
    title: "Главная",
  },
  components: {
    HomeBannerComponent,
    HomeHeroesComponent,
  },
};
</script>

<style lang="stylus">
.home-page {
  display flex
  flex-direction column
  gap 70px
  padding 0
  +below(650px) {
    gap 30px
  }
}
</style>
