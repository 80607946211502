import { render, staticRenderFns } from "./HomeBannerComponent.vue?vue&type=template&id=22723256"
import script from "./HomeBannerComponent.vue?vue&type=script&lang=js"
export * from "./HomeBannerComponent.vue?vue&type=script&lang=js"
import style0 from "./HomeBannerComponent.vue?vue&type=style&index=0&id=22723256&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports